import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import bg from "../assets/imgs/bg/Bg_video_transp.svg";

function Bron(props) {
  const params = useParams();
  const source = props.sources[params.id - 1];
  console.log(source);

  return (
    <div>
      {source.video ? (
        <div className="videobron">
          <div>
            <h1>{source.title}</h1>{" "}
            <img
              src={source.pills}
              alt="De bijhorende skills en thema's van de bron"
            />
          </div>
          {source.paragraphs &&
            source.paragraphs.map((paragraph, i) => (
              <p key={i} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
            ))}
          <div>
            <iframe
              className="nieuw"
              src={`https://www.youtube.com/embed/${source.video}?cc_lang_pref=nl&cc_load_policy=1`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <p
              className="bronvermelding"
              dangerouslySetInnerHTML={{ __html: source.bronvermelding }}
            ></p>
          </div>
        </div>
      ) : (
        <div className="pngbron">
          <img src={source.png} alt={`Bron met de titel "${source.title}"`} />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  sources: state.sources,
});

export default connect(mapStateToProps, null)(Bron);
