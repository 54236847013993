import React from "react";
import bubbelhacker from "../assets/schema/bubbelhacker@2x.png";
import impactchecker from "../assets/schema/Impactchecker@2x.png";
import poolverkenner from "../assets/schema/Poolverkenner@2x.png";
import leugendetector from "../assets/schema/Leugendetector@2x.png";
import censuursensor from "../assets/schema/Censuursensor@2x.png";

function Schemes(props) {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        style={{ maxHeight: "100vh", margins: "0 auto", maxWidth: "100vw" }}
        src={
          props.scheme === "leugendetector"
            ? leugendetector
            : props.scheme === "bubbelhacker"
            ? bubbelhacker
            : props.scheme === "impactchecker"
            ? impactchecker
            : props.scheme === "poolverkenner"
            ? poolverkenner
            : censuursensor
        }
        alt={`Schema van de ${
          props.scheme === "leugendetector"
            ? "leugendetector"
            : props.scheme === "bubbelhacker"
            ? "bubbelhacker"
            : props.scheme === "impactchecker"
            ? "impactchecker"
            : props.scheme === "poolverkenner"
            ? "poolverkenner"
            : "censuursensor"
        }`}
      />
    </div>
  );
}

export default Schemes;
