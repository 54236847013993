import { Route, Routes } from "react-router";
import { bronnen } from "./assets/imports";
import Bronnenbox from "./pages/Bronnenbox";
import Home from "./pages/Home";
import Schemes from "./pages/Schemes";
import { HashRouter } from "react-router-dom";
import Bron from "./pages/Bron";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/bronnenbox" element={<Bronnenbox id={0} />}></Route>
        <Route path="/bronnenbox/:id" element={<Bron />}></Route>

        <Route
          path="/schema/bubbelhacker"
          element={<Schemes scheme={"bubbelhacker"} />}
        ></Route>
        <Route
          path="/schema/impactchecker"
          element={<Schemes scheme={"impactchecker"} />}
        ></Route>
        <Route
          path="/schema/poolverkenner"
          element={<Schemes scheme={"poolverkenner"} />}
        ></Route>
        <Route
          path="/schema/leugendetector"
          element={<Schemes scheme={"leugendetector"} />}
        ></Route>
        <Route
          path="/schema/censuursensor"
          element={<Schemes scheme={"censuursensor"} />}
        ></Route>

        <Route path="/" element={<Home />}></Route>
      </Routes>
    </div>
  );
}

export default App;
