import { bronnen, pillsVideobronnen } from "../../assets/imports";

export const initialState = {
  sources: [
    {
      id: 1,
      title: "Vrouwen in actie!",
      themes: ["WOMAN"],
      skills: [1, 3, 4],
      png: bronnen.bron1,
    },
    {
      id: 2,
      title: "Vrouwen tegen honger!",
      themes: ["WOMAN"],
      skills: [1, 3, 4],
      png: bronnen.bron2,
    },
    {
      id: 3,
      title: "Vrouwen in een hoekje ...",
      themes: ["knip & plak", "WOMAN"],
      skills: [1, 3, 4],
      png: bronnen.bron3,
    },
    {
      id: 4,
      title: "Vrouwen in de wapenindustrie",
      themes: ["alles kits!", "WOMAN"],
      skills: [1, 3, 4],
      png: bronnen.bron4,
    },
    {
      id: 5,
      title: "Zomer 1944 door de ogen van de Nazi's",
      themes: [
        "bubbels",
        "alles kits!",
        "knip & plak",
        "het einde in zicht ...",
      ],
      skills: [0, 1, 3],
      png: bronnen.bron5,
    },
    {
      id: 6,
      title: "Post voor bezet België",
      themes: ["bubbels", "luchtpost"],
      skills: [1, 2, 3],
      png: bronnen.bron6,
    },
    {
      id: 7,
      title: "Post voor bezet Duitsland!",
      themes: ["bubbels", "luchtpost"],
      skills: [1, 2, 4],
      png: bronnen.bron7,
    },
    {
      id: 8,
      title: "De belofte van werken in Duitsland",
      themes: ["alles kits!", "knip & plak", "onder de Duitsers"],
      skills: [1, 2, 3],
      video: "2OD-6RUkfwg",
      pills: pillsVideobronnen.pillsBelofteWerkenDuitsland,
      bronvermelding:
        "Bron: <span>Duitse bioscoopjournaals over de vrijwillige tewerkstelling</span>, data onbekend.",
    },
    {
      id: 9,
      title: "Het lot van arbeiders in Duitsland",
      themes: ["bubbels", "onder de Duitsers"],
      skills: [1, 2, 4],
      png: bronnen.bron8,
    },
    {
      id: 10,
      title: "Spot van de ergste soort",
      themes: ["bubbels"],
      skills: [1, 2, 3],
      video: "fOjUAXl6CyY",
      pills: pillsVideobronnen.pillsSpotVDErgsteSoort,
      bronvermelding:
        "Bron: <span>Vom Bäumlein, das andere Blätter hat gewollt</span> ('Van het boompje dat andere bladeren had gewild', tekenfilm door Heinz Tischmeyer, gebaseerd op een gedicht van Friedrich Rückert), 1940.",
    },
    {
      id: 11,
      title: "De risico's van sluikpers",
      themes: ["bubbels", "sneaky van A naar B"],
      skills: [1, 2, 4],
      video: "LcTeJF2pzMs",
      pills: pillsVideobronnen.pillsRisicosSluikpers,
      bronvermelding:
        "Bron: <span>How Belgium gets the real news</span>, British Paramount News, datum onbekend. Wij hebben getracht om alle rechthebbenden te achterhalen. Mochten er desondanks filmfragmenten afgebeeld worden waarop u meent rechten te hebben, dan vernemen wij dat graag.",
    },
    {
      id: 12,
      title: "Zomer 1944 door de ogen van de communisten",
      themes: ["het einde in zicht ..."],
      skills: [0, 1, 3],
      png: bronnen.bron12,
    },
    {
      id: 13,
      title: "Missie volbracht!",
      themes: ["bubbels", "sneaky van A naar B"],
      skills: [0, 3, 4],
      video: "bESRjhTcqfM",
      pills: pillsVideobronnen.pillsMissieVolbracht,
      paragraphs: [
        "Radio België, met de stem van Jan Moedwil, neemt vanuit Londen afscheid van de luisteraar.",
      ],
      bronvermelding:
        "Bron: <span>Afscheid Jan Moedwil bij het einde van de uitzendingen van Radio België</span>, RB, Londen, 16.9.1944 uit <span>België bezet en bevrijd, originele klankfragmenten uit de periode 1940-1944</span>, BRTN, Brussel, c. 1997. 606228, Collectie Stad Antwerpen, Erfgoedbibliotheek Hendrik Conscience.",
    },
    {
      id: 14,
      title: "Moed inspreken",
      themes: ["bubbels", "idee IDEE"],
      skills: [0, 3, 4],
      video: "5TZcBx_YY34",
      pills: pillsVideobronnen.pillsMoedInspreken,
      paragraphs: [
        "Lees of beluister de slogan van Radio België vanuit Londen.",
        '"Vandaag als een gebed<br>morgen als een striemende zweepslag bitsig soms,<br>dan weer vloekend<br>met de ongunstige oorlogsfeiten <br>maar steeds krachtig als een rots<br>hoop uit beton gegoten<br>een onverstoorbare zekerheid dat<br>wij doen ons best,<br>zonder erop te boffen, <br>toch krijgen we ze wel, <strong>de moffen</strong>!"',
      ],
      bronvermelding:
        "Bron: <span>Jan Moedwil bij het einde van een radiouitzending</span>, RB, Londen, s.d. uit <span>België bezet en bevrijd, originele klankfragmenten uit de periode 1940-1944</span>, BRTN, Brussel, c. 1997. 606228, Collectie Stad Antwerpen, Erfgoedbibliotheek Hendrik Conscience.",
    },
    {
      id: 15,
      title: "V-symbolen",
      themes: ["V van verschil"],
      skills: [0, 3],
      png: bronnen.bron15,
    },
    {
      id: 16,
      title: "Nog meer V-symbolen!",
      themes: ["idee IDEE", "V van verschil"],
      skills: [0, 3],
      png: bronnen.bron16,
    },
    {
      id: 17,
      title: "België bezet, maar alles oké?",
      themes: ["alles kits!", "knip & plak", "bubbels"],
      skills: [2, 3, 4],
      png: bronnen.bron17,
    },
    {
      id: 18,
      title: "Lachen met 'den Duits'",
      themes: ["idee IDEE"],
      skills: [0, 1, 3],
      png: bronnen.bron18,
    },
    {
      id: 19,
      title: "Doe mee!",
      themes: ["sneaky van A naar B"],
      skills: [1, 3, 4],
      png: bronnen.bron19,
    },
    {
      id: 20,
      title: "Een communistische krant",
      themes: ["idee IDEE"],
      skills: [0, 1, 3],
      png: bronnen.bron20,
    },
  ],
};

function reducer(store = initialState, action) {
  switch (action.type) {
    /*
    VOORBEELDEN

     case "FETCH_PAGE_INFO":
      return {
        ...store,
      };
    case "CHANGE_SECTOR":
      return {
        ...store,
        activeSector: action.payload.newSector,
      };
    case "TOGGLE_MENU":
      return {
        ...store,
        isMenuOpen: !store.isMenuOpen,
      };
    case "CHANGE_SERVICE":
      return {
        ...store,
        services: {
          ...store.services,
          activeService: action.payload.newService,
        },
      };
    */

    default:
      return store;
  }
}

export default reducer;
