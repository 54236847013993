import logoMediascanners from "../assets/logo/MEDIASCANNERS_LOGO_WIT@2x.png";
import filterIcon from "../assets/icons/filter_icon.svg";
import closeIcon from "../assets/icons/close-o.svg";
import linkArrowIcon from "../assets/icons/arrow-bottom-left-o.svg";
import skillIconLeugendetector from "../assets/icons/skill-icon_leugendetector.svg";
import skillIconImpactchecker from "../assets/icons/skill-icon_impactchecker.svg";
import skillIconPoolverkenner from "../assets/icons/skill-icon_poolverkenner.svg";
import skillIconBubbelhacker from "../assets/icons/skill-icon_bubbelhacker.svg";
import skillIconCensuursensor from "../assets/icons/skill-icon_censuursensor.svg";

import bron1 from "../assets/bronnen/ehc3_bron_01.png";
import bron2 from "../assets/bronnen/ehc3_bron_02.png";
import bron3 from "../assets/bronnen/ehc3_bron_03.png";
import bron4 from "../assets/bronnen/ehc3_bron_04.png";
import bron5 from "../assets/bronnen/ehc3_bron_05.png";
import bron6 from "../assets/bronnen/ehc3_bron_06.png";
import bron7 from "../assets/bronnen/ehc3_bron_07.png";
import bron8 from "../assets/bronnen/ehc3_bron_08.png";
import bron9 from "../assets/bronnen/ehc3_bron_09.png";
import bron10 from "../assets/bronnen/ehc3_bron_10.png";
import bron11 from "../assets/bronnen/ehc3_bron_11.png";
import bron12 from "../assets/bronnen/ehc3_bron_12.png";
import bron13 from "../assets/bronnen/ehc3_bron_13.png";
import bron14 from "../assets/bronnen/ehc3_bron_14.png";
import bron15 from "../assets/bronnen/ehc3_bron_15.png";
import bron16 from "../assets/bronnen/ehc3_bron_16.png";
import bron17 from "../assets/bronnen/ehc3_bron_17.png";
import bron18 from "../assets/bronnen/ehc3_bron_18.png";
import bron19 from "../assets/bronnen/ehc3_bron_19.png";
import bron20 from "../assets/bronnen/ehc3_bron_20.png";

import pillsMoedInspreken from "../assets/bronnen/Oetang_Vid5_Moed_inspreken@2x.png";
import pillsMissieVolbracht from "../assets/bronnen/Oetang_Vid4_Missie_volbracht@2x.png";
import pillsRisicosSluikpers from "../assets/bronnen/Oetang_Vid3_Risicos_sluikpers@2x.png";
import pillsSpotVDErgsteSoort from "../assets/bronnen/Oetang_Vid2_Spot_van_de_ergste_soort@2x.png";
import pillsBelofteWerkenDuitsland from "../assets/bronnen/Oetang_Vid1_Belofte_van_werken_in_Duitsland@2x.png";

export const baseUrl = "https://www.mediascanners.be";

const skills = [
  {
    id: 0,
    title: "Poolverkenner",
    icon: skillIconPoolverkenner,
    color: "#03F5FF",
  },
  {
    id: 1,
    title: "Bubbelhacker",
    icon: skillIconBubbelhacker,
    color: "#FF9300",
  },
  {
    id: 2,
    title: "Leugendetector",
    icon: skillIconLeugendetector,
    color: "#FFEB00",
  },
  {
    id: 3,
    title: "ImpactChecker",
    icon: skillIconImpactchecker,
    color: "#FF007E",
  },
  {
    id: 4,
    title: "Censuursensor",
    icon: skillIconCensuursensor,
    color: "#A7FF00",
  },
];

export {
  logoMediascanners,
  filterIcon,
  skillIconBubbelhacker,
  skillIconCensuursensor,
  skillIconImpactchecker,
  skillIconLeugendetector,
  skillIconPoolverkenner,
  skills,
  closeIcon,
  linkArrowIcon,
};

export const bronnen = {
  bron1,
  bron2,
  bron3,
  bron4,
  bron5,
  bron6,
  bron7,
  bron8,
  bron9,
  bron10,
  bron11,
  bron12,
  bron13,
  bron14,
  bron15,
  bron16,
  bron17,
  bron18,
  bron19,
  bron20,
};

export const pillsVideobronnen = {
  pillsBelofteWerkenDuitsland,
  pillsMissieVolbracht,
  pillsMoedInspreken,
  pillsRisicosSluikpers,
  pillsSpotVDErgsteSoort,
};
