import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  logoMediascanners,
  skills,
  filterIcon,
  closeIcon,
  linkArrowIcon,
  bronnen,
  baseUrl,
} from "../assets/imports";

function Bronnenbox(props) {
  const bronnenboxId = props.id;
  const { sources } = props;

  const allThemes = sources.flatMap((sources) => sources.themes);
  const themes = [...new Set(allThemes)];
  const [checkedThemes, setCheckedThemes] = React.useState([
    ...themes.map((theme) => true),
  ]);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [allThemesCheck, setAllThemesCheck] = React.useState(true);
  console.log(bronnen.bron2);
  const [checkedSkills, setCheckedSkills] = React.useState([
    true,
    true,
    true,
    true,
    true,
  ]);

  // const handleChangeSkills = (i) => (e) => {
  //   let newCheckedSkills = [...checkedSkills];
  //   newCheckedSkills[i] = !checkedSkills[i];
  //   setCheckedSkills(newCheckedSkills);
  // };
  const handleChangeThemes = (i) => (e) => {
    let newCheckedThemes = [...checkedThemes];
    newCheckedThemes[i] = !checkedThemes[i];
    setCheckedThemes(newCheckedThemes);
    if (newCheckedThemes.includes(false)) {
      setAllThemesCheck(false);
    } else {
      setAllThemesCheck(true);
    }
  };

  const filteredSources = [...sources];
  const [allChecks, setAllChecks] = React.useState([]);
  const [allResults, setAllResults] = React.useState([]);

  const makeAllResults = () => {
    setAllResults([]);
    skills.forEach((skill) => allResults.push(skill.id));
    themes.forEach((theme) => allResults.push(theme));
  };

  const makeAllChecks = () => {
    setAllChecks([]);
    checkedSkills.forEach((skill) => allChecks.push(skill));
    checkedThemes.forEach((theme) => allChecks.push(theme));
  };

  const handleChangeFilter = () => {
    makeAllResults();
    makeAllChecks();

    // reset van de sources
    for (let f = 1; f < sources.length + 1; f++) {
      document.getElementById(`bron${f}`).style.display = "flex";
    }

    // themes filter
    sources.forEach((source) => {
      document.getElementById(`bron${source.id}`).style.display = "none";
      allChecks.forEach((check, i) => {
        if (check === true && source.themes.includes(allResults[i])) {
          document.getElementById(`bron${source.id}`).style.display = "flex";
        }
      });
    });

    // skills filter
    allChecks.forEach((check, i) => {
      if (check === false) {
        sources.forEach((source) => {
          if (source.skills.includes(allResults[i])) {
            document.getElementById(`bron${source.id}`).style.display = "none";
          } else {
          }
        });
      }
    });
  };

  const handleChangeAllThemesCheck = (e, newAllThemesCheck) => {
    makeAllChecks();

    setAllThemesCheck(newAllThemesCheck);
    if (newAllThemesCheck === false) {
      setCheckedThemes([...checkedThemes.map((checkedTheme) => false)]);
    } else {
      setCheckedThemes([...checkedThemes.map((checkedTheme) => true)]);
    }
  };

  return (
    <div className={`bronnenbox bronnenbox--niveau-${bronnenboxId}`}>
      <img
        className="bronnenbox__logo"
        alt="Het logo van De Mediascanners"
        src={logoMediascanners}
      />
      <h1 className="bronnenbox__title">Bronnenbox</h1>
      <button
        className="bronnenbox__filter-btn btn--red"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        filter thema<img src={filterIcon} alt="filter icoon"></img>
      </button>
      <section
        className={`bronnenbox__filter-menu bronnenbox__filter-menu--${
          menuOpen === false ? "closed" : "open"
        }`}
      >
        <img
          className="closeIcon"
          src={closeIcon}
          alt="Kruisje om het menu te sluiten"
          onClick={() => setMenuOpen(!menuOpen)}
        ></img>
        <div className="wrapper"></div>
        {/*<h3>Thema's</h3> */}
        <label className="checkbox__container" style={{ fontWeight: "600" }}>
          <input
            type="checkbox"
            checked={allThemesCheck}
            value={true}
            onChange={(e) => handleChangeAllThemesCheck(e, !allThemesCheck)}
          />
          <span className="checkbox__checkmark"></span>
          Alle thema's
        </label>
        {checkedThemes.map((checkedTheme, i) => (
          <label className="checkbox__container" key={i}>
            <input
              type="checkbox"
              checked={checkedThemes[i]}
              value={checkedThemes[i]}
              onChange={handleChangeThemes(i)}
            />
            <span className="checkbox__checkmark"></span>
            {themes[i]}
          </label>
        ))}
        {/* <h3>Skills</h3>
        {checkedSkills.map((checkedSkill, i) => (
          <label className="checkbox__container" key={i}>
            <input
              type="checkbox"
              checked={checkedSkill}
              value={checkedSkill}
              onChange={handleChangeSkills(i)}
            />
            <span className="checkbox__checkmark"></span>
            <img src={skills[i].icon} alt={skills[i].title} />
            <span style={{ color: skills[i].color }}>{skills[i].title}</span>
          </label>
        ))} */}

        <button
          className="bronnenbox__filter-btn btn--red"
          onClick={() => {
            setMenuOpen(!menuOpen);
            handleChangeFilter();
          }}
        >
          pas filter toe<img src={filterIcon} alt="Filter icoon"></img>
        </button>
      </section>

      <section className="bronnenbox__sources">
        {filteredSources.map((source) => (
          <Link
            to={`/bronnenbox/${source.id}`}
            // target="_blank"
            rel="noopener noreferrer"
            key={source.id}
            id={`bron${source.id}`}
            className="source"
            style={{ display: source.skills.includes() }}
          >
            <div className="source__header">
              <h2 className="source__header-title">{source.title}</h2>

              <img src={linkArrowIcon} alt="Pijl"></img>
            </div>
            <div className="source__body">
              <ul className="source__body-themes">
                {source.themes.map((theme, i) => (
                  <li key={i}>{theme}</li>
                ))}
              </ul>
            </div>
            <div
              className="source__footer"
              // style={{ display: bronnenboxId === 1 ? "none" : "flex" }}
            >
              <ul className="source__footer-skills">
                {source.skills.map((skill, i) => (
                  <li key={i}>
                    <img
                      alt={`De ${skills[skill].title}`}
                      src={skills[skill].icon}
                    />
                  </li>
                ))}
              </ul>
              <div className="number">{source.id}</div>
            </div>
          </Link>
        ))}
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sources: state.sources,
});

export default connect(mapStateToProps, null)(Bronnenbox);
