import React from "react";
import { Link } from "react-router-dom";
import { linkArrowIcon, logoMediascanners, baseUrl } from "../assets/imports";
import Obfuscate from "react-obfuscate";

import Bronnenbox from "./Bronnenbox";
import { HashLink } from "react-router-hash-link";
import logoMenu from "../assets/logo/logo_menu.svg";
import logo from "../assets/logo/MEDIASCANNERS_LOGO_WIT@2x.png";
import skills from "../assets/icons/skill-icon_group.svg";
import logoBib from "../assets/logo/logo_bib.jpg";
import logoOetang from "../assets/logo/naked--no-grunge.png";
import homeHero from "../assets/imgs/HEADER_IMAGE.png";
import homeHero2x from "../assets/imgs/HEADER_IMAGE@2x.png";
import homeLespakket from "../assets/imgs/©LUCID_EHC_27_02_23__015.png";
import homeLespakket2x from "../assets/imgs/©LUCID_EHC_27_02_23__015@2x.png";
import homeTools from "../assets/imgs/hometools1x.png";
import homeTools2x from "../assets/imgs/hometools2x.png";

import downloadHandleiding from "../assets/downloads/mediascanners_00_handleiding.pdf";
import downloadBronfiches from "../assets/downloads/mediascanners_01_bronfiches_A4-recto-2x_(optioneel).pdf";
import downloadSpelbord from "../assets/downloads/mediascanners_02_spelbord_A1_of_4xA3.pdf";
import downloadOpdrachten from "../assets/downloads/mediascanners_03_opdrachten_A4-recto-2x.pdf";
import downloadSkillschemas from "../assets/downloads/mediascanners_04_skillschemas_A4-recto-1x_(optioneel).pdf";

function Home(props) {
  return (
    <div id="home">
      <section className="hero">
        <div className="hero__top">
          <div className="navigation">
            <div className="navigation__left">
              <Link to="/">
                <img src={logoMenu} alt="Logo van Mediascanners"></img>
              </Link>
            </div>
            <div className="navigation__right">
              <HashLink smooth to="#mediascanners" className="navigation__link">
                Wie zijn de Mediascanners?
              </HashLink>
              <HashLink smooth to="#lespakket" className="navigation__link">
                Het lespakket in actie
              </HashLink>
              <HashLink
                smooth
                to="#digitale-tools"
                className="navigation__link"
              >
                Digitale tools
              </HashLink>
              {/* <a
                href="https://experienceantwerp.be/nl/aanbod/schoolbezoek/musea/erfgoedbibliotheek-hendrik-conscience/workshop-cirkelhelden-lager-onderwijs/"
                className="navigation__link navigation__link--highlight"
                target="_blank"
                rel="noreferrer"
              >
                Boek als workshop
              </a> */}
            </div>
          </div>
          <div className="mobile-top">
            <img src={logo} alt="" />
            <h1 style={{ display: "none" }}>Mediascanners</h1>
          </div>
        </div>
        <div className="hero__bottom">
          <h1 style={{ display: "none" }}>
            Mediascanners: het lespakket over informatiestromen van vroeger en
            nu
          </h1>
          <h2 className="title">
            Scan en hack informatiestromen <br></br>van <span>vroeger</span> en{" "}
            <span>nu</span>
          </h2>
          <ul>
            <li>3de graad SO</li>
            <li>15 - 30 leerlingen</li>
            <li>2 lesuren</li>
          </ul>
          <p>
            Het <strong>lespakket Mediascanners</strong> dompelt leerlingen van
            de 3de graad onder in <strong>bronnen uit WO II</strong>. De
            leerlingen werken aan informatiegeletterdheid door samen op zoek te
            gaan naar tijdloze mechanismen. Dat kan via het{" "}
            <HashLink to="#lespakket">gratis lespakket</HashLink> in de klas of
            tijdens een{" "}
            <a
              href="https://consciencebibliotheek.be/nl/workshop-mediascanners"
              target="_blank"
              rel="noreferrer"
            >
              begeleide workshop
            </a>{" "}
            in de Erfgoedbibliotheek Hendrik Conscience in Antwerpen.
          </p>
          <HashLink className="btn--red" to="#lespakket">
            Ontdek het pakket
          </HashLink>
        </div>
      </section>
      <main>
        <section className="mediascanners">
          <div className="mediascanners__left" id="mediascanners">
            <h2 className="mediascanners__title">Wie zijn de Mediascanners?</h2>
            <p>
              Het fictieve informatiebedrijf Mediascanners scant en{" "}
              <strong>onderzoekt informatiestromen</strong> van vroeger. Hun
              doel? Met deze inzichten mensen gidsen door de complexe
              informatiestromen vandaag. Gaandeweg{" "}
              <strong>specialiseren</strong> leerling-mediascanners zich als
              bubbelhacker, leugendetector, censuursensor, impactchecker of
              poolverkenner … of allemaal tegelijk.
            </p>
          </div>
          <div className="mediascanners__right">
            <img src={skills} alt="" />
          </div>
        </section>
        <section className="lespakket">
          <img
            className="lespakket__left"
            src={homeLespakket}
            srcSet={`${homeLespakket2x} 2x`}
            alt="Een leerling aan het werk met een Mediascanner-opdracht"
          />

          <div className="lespakket__right" id="lespakket">
            <h2 className="lespakket__title">Het lespakket in actie</h2>
            <p>
              Door middel van <strong>zelfstandige groepsopdrachten</strong>{" "}
              duiken leerlingen in sluikpers, luchtpost, spotprenten,
              bioscoopjournaals en ander authentiek WO II-materiaal. Ze trainen
              zichzelf in het herkennen van dynamieken zoals censuur of
              propaganda.
            </p>
            <p>
              De leerlingen verhogen zo het{" "}
              <strong>informatiegeletterd profiel van de klas</strong>. In een{" "}
              <strong>creatieve slotopdracht</strong> geven ze zelf vorm aan
              tips voor diverse doelgroepen.
            </p>
          </div>
        </section>
        <section className="digitale-tools" id="digitale-tools">
          <div className="digitale-tools__left">
            <h2 className="digitale-tools__title">Digitale tools</h2>
            <p>
              Ondersteunende digitale tools lopen als een rode draad doorheen de
              workshop: een <strong>bronnenbox</strong> met bronnen van vroeger
              en nu, <strong>inzichtelijke schema’s</strong> over
              informatiegeletterdheid en een handige{" "}
              <strong>begeleidende presentatie</strong> voor de leerkracht.
            </p>
            <div>
              <Link
                className="btn--red"
                to="/bronnenbox"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-external"></i>Open de bronnenbox
              </Link>
              <a
                className="btn--red"
                href={`${baseUrl}/presentatie`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-digibord"></i>Start de digitale presentatie{" "}
              </a>
            </div>
          </div>
          <div className="digitale-tools__right">
            <img
              src={homeTools}
              srcSet={(homeTools + " 1x", homeTools2x + " 2x")}
              alt="De mobiele bronnenbox voor leerlingen en de digitale presentatietool voor de begeleider."
            />
          </div>
        </section>
        <div className="downloads">
          <h2>Downloads, prints en tools</h2>
          <h3>Handleiding</h3>
          <div>
            <p>
              De handleiding is opgevat als een visuele doorloop, met
              didactische tips én een skills-spiekblad.
            </p>
            <div>
              <a
                href={downloadHandleiding}
                className="btn--red"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-download"></i>Handleiding
              </a>
            </div>
          </div>
          <h3>Digitale tools</h3>
          <div>
            <p>
              Deze digitale tools zijn de ruggengraat van het lespakket, één
              voor de leerling en één voor de leerkracht.
            </p>
            <div>
              <Link
                className="btn--red"
                to="/bronnenbox"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-external"></i>Open de bronnenbox
              </Link>
              <a
                className="btn--red"
                href={`${baseUrl}/presentatie`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-digibord"></i>Start de digitale presentatie
              </a>
            </div>
          </div>
          <h3>Prints</h3>
          <div>
            <ul>
              <li>
                <a href={downloadBronfiches} target="_blank" rel="noreferrer">
                  <i className="icon-download"></i>Bronnenfiches
                </a>
                <span>A4 - recto - 2x (optioneel)</span>
              </li>
              <li>
                <a href={downloadSpelbord} target="_blank" rel="noreferrer">
                  <i className="icon-download"></i>Spelbord met pionnen
                </a>
                <span>A1 of 4 x A3</span>
              </li>
              <li>
                <a href={downloadOpdrachten} target="_blank" rel="noreferrer">
                  <i className="icon-download"></i>Opdrachtfiches
                </a>
                <span>A4 - recto - 2x</span>
              </li>
              <li>
                <a href={downloadSkillschemas} target="_blank" rel="noreferrer">
                  <i className="icon-download"></i>Skillschema's
                </a>
                <span>A4 - recto - 1x (optioneel)</span>
              </li>
            </ul>
          </div>
        </div>
      </main>
      <footer>
        <div className="footer__tekst">
          <p>
            Heb je nog vragen over de workshop, maak je graag een afspraak om
            het materiaal op locatie te ontdekken of wil je meteen een workshop
            boeken in de Erfgoedbibliotheek? Bezoek dan{" "}
            <a
              href="https://consciencebibliotheek.be/nl/pagina/workshop-mediascanners"
              target="_blank"
              rel="noreferrer"
            >
              onze website
            </a>{" "}
            of mail naar de{" "}
            <Obfuscate
              email="educatie_ehc@antwerpen.be"
              headers={{
                subject: "Workshop Cirkelhelden boeken",
              }}
            >
              educatieve&nbsp;dienst
            </Obfuscate>{" "}
            van de Erfgoedbibliotheek Hendrik Conscience.
          </p>
        </div>
        <div className="footer__logos">
          <a
            href="https://consciencebibliotheek.be/nl"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={logoBib}
              alt="Logo van de Erfgoedbibliotheek Hendrik Conscience"
            ></img>
          </a>
          <a href="https://oetang.be/" rel="noreferrer">
            <img
              src={logoOetang}
              alt="Logo van Oetang Learning Designers"
            ></img>
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Home;
